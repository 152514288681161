<template>
     <Layout>
        <div class="terms container-fluid">
        <div class="container">
            <div class="content" v-html="content"></div>
        </div>
    </div>
     </Layout>
</template>

<script>
// Import Methods, Packages, Files
import Breadcrumb from "@/components/Breadcrumb.vue";
import ImageWithText from "@/components/Image-with-Text.vue";
import { computed } from 'vue'
import { useRoute } from 'vue-router';

export default {
    name: 'Terms',
    components: {
        Breadcrumb,
        ImageWithText
    },
    setup() {
        // Calling, Declarations, Data
        const route = useRoute();
        const pageTitle = computed(() => route.meta.title);
        const content = `
            <h3 class="fw-bold">الشروط والأحكام</h3>
    <p>مرحباً بكم في موقع [اسم الموقع]. يُرجى قراءة الشروط والأحكام التالية بعناية قبل استخدام الموقع. باستخدامك للموقع، فإنك توافق على الالتزام بالشروط والأحكام المذكورة أدناه.</p>
    <div class="mt-2">
         <h4 class="fw-bold">1. قبول الشروط</h4>
        <p>بدخولك واستخدامك للموقع، فإنك توافق على الالتزام بالشروط والأحكام الخاصة بنا. في حال عدم موافقتك على أي جزء من هذه الشروط، يُرجى التوقف عن استخدام الموقع فوراً.</p>
    </div>
   
   <div class="mt-2">
        <h4 class="fw-bold">2. استخدام الموقع</h4>
        <ul>
            <li>يُستخدم الموقع لتمكين الطلاب من البحث عن الفرص المتاحة التي تتناسب مع قدراتهم والتقديم عليها.</li>
            <li>يجب أن يكون التسجيل في الموقع بمعلومات صحيحة ودقيقة.</li>
            <li>يُحظر استخدام الموقع لأي غرض غير قانوني أو يتعارض مع هذه الشروط.</li>
            <li>يتعهد المستخدمون بعدم نشر أي محتوى مسيء أو ينتهك حقوق الآخرين أو يحتوي على مواد غير لائقة.</li>
        </ul>
    </div>
    
    <div class="mt-2">
        <h4 class="fw-bold">3. الحسابات والأمان</h4>
        <ul>
            <li>يجب على المستخدمين الحفاظ على سرية معلومات تسجيل الدخول الخاصة بهم.</li>
            <li>يُعتبر المستخدم مسؤولاً عن أي نشاط يتم من خلال حسابه.</li>
            <li>يحق للموقع إيقاف أو إلغاء حساب المستخدم في حال انتهاك الشروط والأحكام.</li>
        </ul>
    </div>
    
    <div class="mt-2">
        <h4 class="fw-bold">4. الخصوصية</h4>
        <p>نحن نحترم خصوصيتك ونتعامل مع معلوماتك الشخصية بسرية. يُرجى مراجعة سياسة الخصوصية الخاصة بنا لمعرفة كيفية جمع واستخدام وحماية معلوماتك.</p>
    </div>
    
    <div class="mt-2">
        <h4 class="fw-bold">5. حقوق الملكية الفكرية</h4>
        <p>جميع المحتويات والمواد الموجودة على الموقع، بما في ذلك النصوص والصور والشعارات، هي ملكية للموقع ومحفوظة بموجب حقوق النشر. يُمنع إعادة إنتاج أو توزيع أي من محتويات الموقع دون إذن كتابي مسبق.</p>
    </div>
    
    <div class="mt-2">
         <h4 class="fw-bold">6. إخلاء المسؤولية</h4>
        <p>يُقدّم الموقع الفرص المتاحة كما هي، ولا يضمن دقة أو توفر هذه الفرص في جميع الأوقات. المستخدمون يتحملون المسؤولية الكاملة عن قراراتهم بالتقديم على الفرص.</p>
    </div>
    
    <div class="mt-2">
        <h4 class="fw-bold">7. التعديلات على الشروط والأحكام</h4>
        <p>يحتفظ الموقع بالحق في تعديل هذه الشروط والأحكام في أي وقت. سيتم إشعار المستخدمين بالتعديلات عبر البريد الإلكتروني أو من خلال الموقع.</p>
    </div>
   <div class="mt-2">
     <h4 class="fw-bold">8. الاتصال بنا</h4>
     <p>لأي استفسارات أو توضيحات حول هذه الشروط والأحكام، يُرجى الاتصال بنا عبر [بريد إلكتروني الموقع] أو من خلال صفحة "اتصل بنا" على الموقع.</p>
    </div>
    
        `

        // Return
        return {
            pageTitle,
            content
        }
    }
}
</script>

<style scoped>
.terms {
  padding-top: 52px;
  padding-bottom: 52px;
}
</style>